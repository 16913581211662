import React from "react";
import CreateProduct from "../components/product/CreateProduct";

const Product = () => {
  return (
    <div>
      <CreateProduct />
    </div>
  );
};

export default Product;
