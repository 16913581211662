import React from "react";
import UserSignin from "../components/auth/UserSignin";

const Signin = () => {
  return (
    <div>
      <UserSignin />
    </div>
  );
};

export default Signin;
